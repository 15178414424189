<template>
  <div class="flex flex-1">
    <div v-if="selected.length" class="animal-boxes mr-1">
      <animal-box
        v-for="(animal, index) in selected"
        :key="animal.id"
        :animal="animal"
        class="border border-gray-400"
        :class="{ 'border-t-0': index !== 0, 'rounded-t-md': index === 0 }"
        @removeanimal="setSelectedAnimals(animal, false)"
      />
      <div
        class="flex justify-center rounded-b-md border border-t-0 border-gray-400 bg-white p-6"
      >
        <base-button
          color="primary"
          class="w-full"
          :disabled="!compareButtonEnabled"
          :to="
            compareButtonEnabled
              ? {
                  name: 'animal-comparison',
                  params: {
                    animal: selected[0].id,
                    compareAnimal: selected[1].id,
                  },
                }
              : null
          "
        >
          Compare
        </base-button>
      </div>
    </div>
    <table-container :theads="theads">
      <div slot="tableHeader" class="w-full">
        <form class="w-full" @submit.prevent="fetch(null)">
          <div class="flex items-end justify-between space-x-3">
            <div class="flex flex-1 flex-col space-y-2">
              <base-label for="pet"> Pet </base-label>
              <base-input
                id="pet"
                ref="petSearch"
                v-model="animalName"
                placeholder="Search for name or ID"
              />
            </div>
            <div class="flex flex-1 flex-col space-y-2">
              <base-label for="owner"> Owner </base-label>
              <base-input
                id="owner"
                v-model="animalOwnerName"
                placeholder="Search for name or ID"
              />
            </div>
            <div class="flex-1">
              <base-select
                class="w-full text-sm"
                label-attribute="name"
                value-attribute="id"
                :options="animalTypes"
                placeholder="Animal type"
                :value="animalTypeId"
                data-testid="animal-type-dropdown"
                @change="animalTypeId = $event"
              />
            </div>
            <div class="flex-1">
              <base-select
                class="w-full text-sm"
                label-attribute="name"
                value-attribute="id"
                :options="insuranceList"
                placeholder="Insurance"
                :value="insuranceId"
                @change="insuranceId = $event"
              />
            </div>

            <div class="inline-flex space-x-2">
              <base-button
                :loading="loading"
                color="primary"
                type="submit"
                data-testid="search-button"
              >
                <font-awesome-icon icon="search" />
                <span class="ml-2 inline-flex">Search</span>
              </base-button>
              <base-button
                v-if="searched"
                color="cancel"
                type="button"
                @click="clearSearch"
              >
                <fv-icon icon="close" class="h-4 w-4" />
                <span class="ml-2 inline-flex">Clear</span>
              </base-button>
            </div>
          </div>
        </form>
      </div>

      <tr
        v-for="animal in animals"
        :key="animal.id"
        class="text-left"
        :class="{ 'bg-gray-100': isAnimalSelected(animal) }"
      >
        <table-data>
          <div class="flex flex-col space-y-1">
            <input
              :disabled="selected.length === 2 && !isAnimalSelected(animal)"
              :checked="isAnimalSelected(animal)"
              type="checkbox"
              class="h-6 w-6"
              @change="
                event => setSelectedAnimals(animal, event.target.checked)
              "
            />
          </div>
        </table-data>
        <table-data
          ><span class="text-gray-700">{{ animal.id }}</span></table-data
        >
        <table-data>
          <div class="inline-flex flex-col space-y-2">
            <p>
              {{ animal.name }}
              <span
                v-if="animal.appointments.length"
                v-tooltip.right="{
                  content: tooltipText(
                    animal.appointments.length,
                    'video appointment'
                  ),
                  classes: 'history-tooltip',
                }"
                class="ml-2 rounded bg-gray-300 px-2 py-1 text-gray-600"
              >
                <font-awesome-icon icon="video" />
                {{ animal.appointments.length }}
              </span>
              <span
                v-if="animal.questions.length"
                v-tooltip.right="{
                  content: tooltipText(animal.questions.length, 'question'),
                  classes: 'history-tooltip',
                }"
                class="ml-2 rounded bg-gray-300 px-2 py-1 text-gray-600"
              >
                <font-awesome-icon icon="info-circle" />
                {{ animal.questions.length }}
              </span>
              <span
                v-if="animal.voicecalls.length"
                v-tooltip.right="{
                  content: tooltipText(animal.voicecalls.length, 'voice call'),
                  classes: 'history-tooltip',
                }"
                class="ml-2 rounded bg-gray-300 px-2 py-1 text-gray-600"
              >
                <font-awesome-icon icon="phone" />
                {{ animal.voicecalls.length }}
              </span>
            </p>
          </div>
        </table-data>
        <table-data>
          <div
            class="flex flex-col space-y-1"
            data-testid="animal-type-table-data"
          >
            <p>
              {{
                animal.animal_type && animal.animal_type.name
                  ? animal.animal_type.name
                  : '-'
              }}
            </p>
          </div>
        </table-data>
        <table-data>
          <div class="flex flex-col space-y-1">
            <p>{{ animal.first_name }} {{ animal.last_name }}</p>
          </div>
        </table-data>
        <table-data>
          <div class="flex flex-col space-y-1">
            <p>
              {{
                animal.insurance_type && animal.insurance_type.name
                  ? animal.insurance_type.name
                  : '-'
              }}
            </p>
          </div>
        </table-data>
        <table-data>
          <div class="flex flex-col space-y-1">
            <p>
              {{ format(new Date(animal.created_at), 'yyyy-MM-dd') }}
            </p>
          </div>
        </table-data>
      </tr>

      <div
        slot="tableFooter"
        class="flex w-full items-baseline justify-between"
      >
        <div
          v-if="(!animals.length && loading) || (!animals.length && !loading)"
          slot="tableLoading"
          class="flex w-full items-center justify-center"
        >
          <span class="py-8 text-sm">
            <base-spinner v-if="!animals.length && loading" />
            <span v-if="!animals.length && !loading">No animals found</span>
          </span>
        </div>
        <div
          v-if="animalList.data && animalList.data.length"
          class="flex w-full items-baseline justify-between"
        >
          <pagination-buttons
            :pagination-list="animalList"
            :loading="loading"
            @previous="fetch(animalList.prev_page_url)"
            @next="fetch(animalList.next_page_url)"
          />
          <div>
            Viewing {{ animalList.data.length }} of
            {{ animalList.total }} entries
          </div>
        </div>
      </div>
    </table-container>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapState, mapGetters, mapActions } from 'vuex';

import PaginationButtons from '@/UI/PaginationButtons';
import AnimalBox from '@/components/admin/animals/AnimalBox';

export default {
  components: {
    PaginationButtons,
    AnimalBox,
  },

  data() {
    return {
      selected: [],
      loading: false,
      format,
      searched: false,
      animalName: '',
      animalOwnerName: '',
      animalTypeId: '',
      insuranceId: '',
      theads: [
        '',
        'Id',
        'Name',
        'Animal type',
        'Owner',
        'Insurance company',
        'Date added',
      ],
    };
  },

  computed: {
    ...mapState('admin', ['countryId']),
    ...mapState('admin/animals', ['animalList', 'animal', 'compareAnimal']),
    ...mapGetters({ locale: 'getLocale' }),
    ...mapGetters('insurance', {
      insuranceList: 'getInsuranceList',
    }),
    ...mapGetters('animal', {
      animalTypes: 'getAnimalTypes',
    }),
    animals() {
      return this.animalList.data || [];
    },
    compareButtonEnabled() {
      return this.selected?.length > 1;
    },
  },

  mounted() {
    if (
      !this.insuranceList.length ||
      this.insuranceList[0].country_id !== this.countryId
    ) {
      this.fetchCompaniesByCountryId({ countryId: this.countryId, params: {} });
    }
    if (!this.animalTypes.length) {
      this.fetchAnimalTypes();
    }
    this.$refs.petSearch.$el.focus();
    this.fetchInitial();

    if (this.animal?.id) {
      this.selected.push(this.animal);
    }
    if (this.compareAnimal?.id) {
      this.selected.push(this.compareAnimal);
    }
  },

  beforeDestroy() {
    this.clearAnimalsForCompare();
  },

  methods: {
    ...mapActions('admin/animals', [
      'fetchAnimals',
      'clearAnimals',
      'clearAnimalsForCompare',
    ]),
    ...mapActions('insurance', ['fetchCompaniesByCountryId']),
    ...mapActions('animal', ['fetchAnimalTypes']),
    fetchInitial() {
      this.loading = true;
      // Clear animals before getting them (clearing if switching country)
      this.clearAnimals();

      this.fetchAnimals({
        data: {
          country_id: this.countryId,
          locale: this.locale,
        },
      }).finally(() => {
        this.loading = false;
      });
    },
    fetch(pageUrl) {
      this.loading = true;
      this.clearAnimals();

      this.searched = !!(
        this.animalName ||
        this.animalOwnerName ||
        this.animalTypeId ||
        this.insuranceId
      );

      const payload = {
        data: {
          animal_name: this.animalName,
          animal_owner_name: this.animalOwnerName,
          animal_type_id: this.animalTypeId,
          insurance_id: this.insuranceId,
          // per_page: 8,
          country_id: this.countryId,
          locale: this.locale,
        },
      };

      payload.pageUrl = pageUrl || '';

      this.fetchAnimals(payload)
        .catch(e => {
          console.log({ e });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearSearch() {
      this.searched = false;
      this.animalName = '';
      this.animalOwnerName = '';
      this.animalTypeId = '';
      this.insuranceId = '';
      this.fetchInitial();
    },
    tooltipText(amount, type) {
      return `${amount} ${type}${amount > 1 ? 's' : ''}`;
    },
    setSelectedAnimals(animal, value) {
      if (value) {
        this.selected.push(animal);
      } else {
        const index = this.selected.findIndex(a => a.id === animal.id);
        this.selected.splice(index, 1);
      }
    },
    isAnimalSelected(animal) {
      return this.selected.findIndex(a => a.id === animal.id) > -1;
    },
  },
};
</script>
<style scoped lang="postcss">
input,
button,
select {
  height: 38px;
  padding: 0.5rem;
}
button {
  padding: 0.5rem 2rem !important;
}
input[type='checkbox'] {
  filter: saturate(0.4);
}
.animal-boxes {
  margin-top: 6.6rem;
}
</style>
