<template>
  <div
    v-if="animal && animal.name"
    class="relative flex w-64 flex-col bg-white p-6 leading-7"
  >
    <div class="flex items-center justify-between">
      <div class="inline-flex items-center space-x-6">
        <h2 class="flex justify-between text-lg font-black">
          {{ animal.name }}
        </h2>
      </div>
      <base-button
        size="sm"
        color="transparent"
        @click="$emit('removeanimal', animal)"
      >
        <fv-icon icon="close" />
      </base-button>
    </div>
    <div class="text-sm text-gray-700">
      <p>
        {{ animal.id }}
      </p>
      <p class="">
        {{
          animal.animal_type && animal.animal_type.name
            ? animal.animal_type.name
            : '-'
        }}, {{ animalGender }}
      </p>
    </div>
    <desc-list class="text-sm">
      <desc-item>
        <desc-title> Owner </desc-title>
        <desc-data>
          <p class="font-semibold">
            {{ animalOwner }}
          </p>
        </desc-data>
      </desc-item>
      <desc-item>
        <desc-title> Info </desc-title>
        <desc-data>
          <p class="">
            {{ animal.birthdate }}
          </p>
          <p class="">
            <span class="">
              {{
                animal.insurance_type && animal.insurance_type.name
                  ? animal.insurance_type.name
                  : '-'
              }}
            </span>
          </p>
        </desc-data>
      </desc-item>
    </desc-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    animal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('animal', {
      genderOptions: 'getGenderOptions',
    }),
    animalGender() {
      const res = this.genderOptions.find(
        gender => gender.value === this.animal.gender
      );
      return res.name;
    },
    animalOwner() {
      // eslint-disable-next-line camelcase
      if (this.animal.user?.first_name && this.animal.user?.last_name) {
        return `${this.animal.user.first_name} ${this.animal.user.last_name}`;
      }

      if (this.animal.first_name && this.animal.last_name) {
        return `${this.animal.first_name} ${this.animal.last_name}`;
      }

      return this.animal.user_id;
    },
  },
  methods: {},
};
</script>
