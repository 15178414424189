var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-1" },
    [
      _vm.selected.length
        ? _c(
            "div",
            { staticClass: "animal-boxes mr-1" },
            [
              _vm._l(_vm.selected, function (animal, index) {
                return _c("animal-box", {
                  key: animal.id,
                  staticClass: "border border-gray-400",
                  class: {
                    "border-t-0": index !== 0,
                    "rounded-t-md": index === 0,
                  },
                  attrs: { animal: animal },
                  on: {
                    removeanimal: function ($event) {
                      return _vm.setSelectedAnimals(animal, false)
                    },
                  },
                })
              }),
              _c(
                "div",
                {
                  staticClass:
                    "flex justify-center rounded-b-md border border-t-0 border-gray-400 bg-white p-6",
                },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        color: "primary",
                        disabled: !_vm.compareButtonEnabled,
                        to: _vm.compareButtonEnabled
                          ? {
                              name: "animal-comparison",
                              params: {
                                animal: _vm.selected[0].id,
                                compareAnimal: _vm.selected[1].id,
                              },
                            }
                          : null,
                      },
                    },
                    [_vm._v(" Compare ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "table-container",
        { attrs: { theads: _vm.theads } },
        [
          _c(
            "div",
            {
              staticClass: "w-full",
              attrs: { slot: "tableHeader" },
              slot: "tableHeader",
            },
            [
              _c(
                "form",
                {
                  staticClass: "w-full",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.fetch(null)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-end justify-between space-x-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-1 flex-col space-y-2" },
                        [
                          _c("base-label", { attrs: { for: "pet" } }, [
                            _vm._v(" Pet "),
                          ]),
                          _c("base-input", {
                            ref: "petSearch",
                            attrs: {
                              id: "pet",
                              placeholder: "Search for name or ID",
                            },
                            model: {
                              value: _vm.animalName,
                              callback: function ($$v) {
                                _vm.animalName = $$v
                              },
                              expression: "animalName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-1 flex-col space-y-2" },
                        [
                          _c("base-label", { attrs: { for: "owner" } }, [
                            _vm._v(" Owner "),
                          ]),
                          _c("base-input", {
                            attrs: {
                              id: "owner",
                              placeholder: "Search for name or ID",
                            },
                            model: {
                              value: _vm.animalOwnerName,
                              callback: function ($$v) {
                                _vm.animalOwnerName = $$v
                              },
                              expression: "animalOwnerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-1" },
                        [
                          _c("base-select", {
                            staticClass: "w-full text-sm",
                            attrs: {
                              "label-attribute": "name",
                              "value-attribute": "id",
                              options: _vm.animalTypes,
                              placeholder: "Animal type",
                              value: _vm.animalTypeId,
                              "data-testid": "animal-type-dropdown",
                            },
                            on: {
                              change: function ($event) {
                                _vm.animalTypeId = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-1" },
                        [
                          _c("base-select", {
                            staticClass: "w-full text-sm",
                            attrs: {
                              "label-attribute": "name",
                              "value-attribute": "id",
                              options: _vm.insuranceList,
                              placeholder: "Insurance",
                              value: _vm.insuranceId,
                            },
                            on: {
                              change: function ($event) {
                                _vm.insuranceId = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "inline-flex space-x-2" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                loading: _vm.loading,
                                color: "primary",
                                type: "submit",
                                "data-testid": "search-button",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "search" },
                              }),
                              _c("span", { staticClass: "ml-2 inline-flex" }, [
                                _vm._v("Search"),
                              ]),
                            ],
                            1
                          ),
                          _vm.searched
                            ? _c(
                                "base-button",
                                {
                                  attrs: { color: "cancel", type: "button" },
                                  on: { click: _vm.clearSearch },
                                },
                                [
                                  _c("fv-icon", {
                                    staticClass: "h-4 w-4",
                                    attrs: { icon: "close" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "ml-2 inline-flex" },
                                    [_vm._v("Clear")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._l(_vm.animals, function (animal) {
            return _c(
              "tr",
              {
                key: animal.id,
                staticClass: "text-left",
                class: { "bg-gray-100": _vm.isAnimalSelected(animal) },
              },
              [
                _c("table-data", [
                  _c("div", { staticClass: "flex flex-col space-y-1" }, [
                    _c("input", {
                      staticClass: "h-6 w-6",
                      attrs: {
                        disabled:
                          _vm.selected.length === 2 &&
                          !_vm.isAnimalSelected(animal),
                        type: "checkbox",
                      },
                      domProps: { checked: _vm.isAnimalSelected(animal) },
                      on: {
                        change: function (event) {
                          return _vm.setSelectedAnimals(
                            animal,
                            event.target.checked
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("table-data", [
                  _c("span", { staticClass: "text-gray-700" }, [
                    _vm._v(_vm._s(animal.id)),
                  ]),
                ]),
                _c("table-data", [
                  _c("div", { staticClass: "inline-flex flex-col space-y-2" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(animal.name) + " "),
                      animal.appointments.length
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.right",
                                  value: {
                                    content: _vm.tooltipText(
                                      animal.appointments.length,
                                      "video appointment"
                                    ),
                                    classes: "history-tooltip",
                                  },
                                  expression:
                                    "{\n                content: tooltipText(\n                  animal.appointments.length,\n                  'video appointment'\n                ),\n                classes: 'history-tooltip',\n              }",
                                  modifiers: { right: true },
                                },
                              ],
                              staticClass:
                                "ml-2 rounded bg-gray-300 px-2 py-1 text-gray-600",
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "video" },
                              }),
                              _vm._v(
                                " " + _vm._s(animal.appointments.length) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      animal.questions.length
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.right",
                                  value: {
                                    content: _vm.tooltipText(
                                      animal.questions.length,
                                      "question"
                                    ),
                                    classes: "history-tooltip",
                                  },
                                  expression:
                                    "{\n                content: tooltipText(animal.questions.length, 'question'),\n                classes: 'history-tooltip',\n              }",
                                  modifiers: { right: true },
                                },
                              ],
                              staticClass:
                                "ml-2 rounded bg-gray-300 px-2 py-1 text-gray-600",
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "info-circle" },
                              }),
                              _vm._v(
                                " " + _vm._s(animal.questions.length) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      animal.voicecalls.length
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.right",
                                  value: {
                                    content: _vm.tooltipText(
                                      animal.voicecalls.length,
                                      "voice call"
                                    ),
                                    classes: "history-tooltip",
                                  },
                                  expression:
                                    "{\n                content: tooltipText(animal.voicecalls.length, 'voice call'),\n                classes: 'history-tooltip',\n              }",
                                  modifiers: { right: true },
                                },
                              ],
                              staticClass:
                                "ml-2 rounded bg-gray-300 px-2 py-1 text-gray-600",
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "phone" },
                              }),
                              _vm._v(
                                " " + _vm._s(animal.voicecalls.length) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("table-data", [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-col space-y-1",
                      attrs: { "data-testid": "animal-type-table-data" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              animal.animal_type && animal.animal_type.name
                                ? animal.animal_type.name
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("table-data", [
                  _c("div", { staticClass: "flex flex-col space-y-1" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(animal.first_name) +
                          " " +
                          _vm._s(animal.last_name)
                      ),
                    ]),
                  ]),
                ]),
                _c("table-data", [
                  _c("div", { staticClass: "flex flex-col space-y-1" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            animal.insurance_type && animal.insurance_type.name
                              ? animal.insurance_type.name
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("table-data", [
                  _c("div", { staticClass: "flex flex-col space-y-1" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.format(
                              new Date(animal.created_at),
                              "yyyy-MM-dd"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "flex w-full items-baseline justify-between",
              attrs: { slot: "tableFooter" },
              slot: "tableFooter",
            },
            [
              (!_vm.animals.length && _vm.loading) ||
              (!_vm.animals.length && !_vm.loading)
                ? _c(
                    "div",
                    {
                      staticClass: "flex w-full items-center justify-center",
                      attrs: { slot: "tableLoading" },
                      slot: "tableLoading",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "py-8 text-sm" },
                        [
                          !_vm.animals.length && _vm.loading
                            ? _c("base-spinner")
                            : _vm._e(),
                          !_vm.animals.length && !_vm.loading
                            ? _c("span", [_vm._v("No animals found")])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.animalList.data && _vm.animalList.data.length
                ? _c(
                    "div",
                    {
                      staticClass: "flex w-full items-baseline justify-between",
                    },
                    [
                      _c("pagination-buttons", {
                        attrs: {
                          "pagination-list": _vm.animalList,
                          loading: _vm.loading,
                        },
                        on: {
                          previous: function ($event) {
                            return _vm.fetch(_vm.animalList.prev_page_url)
                          },
                          next: function ($event) {
                            return _vm.fetch(_vm.animalList.next_page_url)
                          },
                        },
                      }),
                      _c("div", [
                        _vm._v(
                          " Viewing " +
                            _vm._s(_vm.animalList.data.length) +
                            " of " +
                            _vm._s(_vm.animalList.total) +
                            " entries "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }