var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.animal && _vm.animal.name
    ? _c(
        "div",
        { staticClass: "relative flex w-64 flex-col bg-white p-6 leading-7" },
        [
          _c(
            "div",
            { staticClass: "flex items-center justify-between" },
            [
              _c("div", { staticClass: "inline-flex items-center space-x-6" }, [
                _c(
                  "h2",
                  { staticClass: "flex justify-between text-lg font-black" },
                  [_vm._v(" " + _vm._s(_vm.animal.name) + " ")]
                ),
              ]),
              _c(
                "base-button",
                {
                  attrs: { size: "sm", color: "transparent" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("removeanimal", _vm.animal)
                    },
                  },
                },
                [_c("fv-icon", { attrs: { icon: "close" } })],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "text-sm text-gray-700" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.animal.id) + " ")]),
            _c("p", {}, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.animal.animal_type && _vm.animal.animal_type.name
                      ? _vm.animal.animal_type.name
                      : "-"
                  ) +
                  ", " +
                  _vm._s(_vm.animalGender) +
                  " "
              ),
            ]),
          ]),
          _c(
            "desc-list",
            { staticClass: "text-sm" },
            [
              _c(
                "desc-item",
                [
                  _c("desc-title", [_vm._v(" Owner ")]),
                  _c("desc-data", [
                    _c("p", { staticClass: "font-semibold" }, [
                      _vm._v(" " + _vm._s(_vm.animalOwner) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "desc-item",
                [
                  _c("desc-title", [_vm._v(" Info ")]),
                  _c("desc-data", [
                    _c("p", {}, [
                      _vm._v(" " + _vm._s(_vm.animal.birthdate) + " "),
                    ]),
                    _c("p", {}, [
                      _c("span", {}, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.animal.insurance_type &&
                                _vm.animal.insurance_type.name
                                ? _vm.animal.insurance_type.name
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }